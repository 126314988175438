$(document).ready(function(){
	$(window).load(function(){
		
		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		iOS_CaretBug();
		responsiveSlick();
		cf7formsubmit();
		tableClass();
		stickySideBar();
		// objLightcase();
		swipeCarousel();
		$(window).on('resize', function(){});
		bootstrapCarousel();
		scrolling();
	});
});

// IOS INPUT BUG FIX
function iOS_CaretBug() {
	var ua = navigator.userAgent,
	scrollTopPosition,
	iOS = /iPad|iPhone|iPod/.test(ua),
	iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

	// ios 11 bug caret position
 	if ( iOS && iOS11 ) {

		$(document.body).on('show.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Get scroll position before moving top
				scrollTopPosition = $(document).scrollTop();

				// Add CSS to body "position: fixed"
				$("body").addClass("iosBugFixCaret");
			}
		});

     $(document.body).on('hide.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Remove CSS to body "position: fixed"
				$("body").removeClass("iosBugFixCaret");

				//Go back to initial position in document
				$(document).scrollTop(scrollTopPosition);
			}
     });
   }
}
function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}

function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}

// Plugins
// function objLightcase(){
// 	$('a[data-rel^=lightcase]').lightcase();
// }

// <div class="slick-responsive" data-breakpoints="1199,4|991,2|659,2|480,1" data-thumb=".sample" data-arrows="true" data-defaultview="4">
function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function cf7formsubmit(){
	
	$('.wpcf7-form').each(function(){
		var form = $(this);
		form.find(".wpcf7-submit, .ajax-loader").wrapAll('<div class="wcf7-wrap-btn"></div>');
		setTimeout(function(){
			var wdSubmit = form.find(".wpcf7-submit").outerWidth();
			form.find('.wcf7-wrap-btn').css({
				'width' : wdSubmit
			});
		}, 100);
	});

	$(document).on('click', '.wpcf7-submit', function(){
		var $btn = $(this);
	  var $ajaxLoader = $btn.parent('.wcf7-wrap-btn').find('.ajax-loader');
	  
	  $btn.addClass('loading');
	  $ajaxLoader.addClass('visible');

		var sitelink = $('.usd').data('usdacct');
		document.addEventListener( 'wpcf7mailsent', function( event ) {
		  location = sitelink + '/thank-you/';
		}, false );
		document.addEventListener( 'wpcf7invalid', function( event ) {
			$btn.removeClass('loading');
		 	$ajaxLoader.removeClass('visible');
		}, false );
	});
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}



/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
function screenBpoints(){
	if( Modernizr.mq('(min-width: 1200px)') ) {
	}
	else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	}
	else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
	}
	else{
	}
}

function stickySideBar(){
	var $navHeight = $('header.site-header').outerHeight();
	if( Modernizr.mq('(min-width: 768px)') ) {
		$('.sticky-bar').each(function(){
			$(this).stickySidebar({
				topSpacing: $navHeight + 30
			});
		});
	}
}
/* BOOTSTRAP CAROUSEL - Enabling the issue on swiping */
function swipeCarousel() {
	$(".carousel").each(function(){
		var $carousel = $(this);
	  $carousel.carousel({
	    interval: false,
	    pause: true
	  });
	  if( $(this).attr('data-touch') ) {
		  $carousel.find( '.carousel-inner' ).swipe( {
		    swipeLeft: function ( event, direction, distance, duration, fingerCount ) {
		      this.parent( ).carousel( 'next' );
		    },
		    swipeRight: function ( ) {
		      this.parent( ).carousel( 'prev' );
		    },
		    threshold: 0,
		    tap: function(event, target) {
		      window.location = $(this).find('.carousel-item.active a').attr('href');
		    },

		    excludedElements:"label, button, input, select, textarea, .noSwipe"
		  } );
		   $carousel.find('.carousel-inner').on('dragstart', 'a', function () {
		    return false;
		  }); 
	  }
	});
}

function bootstrapCarousel() {
	$.fn.linkToCarousel = function( options ) {

		var settings = $.extend( {
			'carousel'	:	'div.carousel',	// you'd better indicate an id if multiple carousel on the page
	 		'slide'		:	'div.item',
		}, options);

		return this.each( function () {
			
			var thumbnails = $(this).children();
			var slides = [settings.carousel,settings.slide].join(' ');

			// clicking on a thumbnail will make the carousel slide
			thumbnails.click( function () {
				$(settings.carousel).carousel(thumbnails.siblings().andSelf().index( $(this) ))
			});

			// after sliding, the new current slide's thumbnail will get the active class
			$(settings.carousel).bind('slid', function (event) {
				var index = $(slides).index($(slides+'.active'));
				thumbnails.removeClass('active');
				thumbnails.eq(index).addClass('active');
			});

		});

	};

	// adds slide animation support to carousel to IE
	$.fn.addJQueryAnimate = function( options ) {
		return this.each( function () {
			$('#realisations-carousel').data().carousel.slide = function (type, next) {
		      if(!$.support.transition && this.$element.hasClass('slide')) {
		        this.$element.find('.item').stop(true, true); //Finish animation and jump to end.
		      }
		      var $active = this.$element.find('.active')
		        , $next = next || $active[type]()
		        , isCycling = this.interval
		        , direction = type == 'next' ? 'left' : 'right'
		        , fallback  = type == 'next' ? 'first' : 'last'
		        , that = this

		      this.sliding = true

		      isCycling && this.pause()

		      $next = $next.length ? $next : this.$element.find('.item')[fallback]()

		      if ($next.hasClass('active')) return

		      if (!$.support.transition && this.$element.hasClass('slide')) {
		        // this.$element.trigger(e)
		        // if (e.isDefaultPrevented()) return
		        $active.animate({left: (direction == 'right' ? '100%' : '-100%')}, 600, function(){
		            $active.removeClass('active')
		            that.sliding = false
		            setTimeout(function () { that.$element.trigger('slid') }, 0)
		        })
		        $next.addClass(type).css({left: (direction == 'right' ? '-100%' : '100%')}).animate({left: '0'}, 600,  function(){
		            $next.removeClass(type).addClass('active')
		        })
		      } else if($.support.transition && this.$element.hasClass('slide')) {
		        $next.addClass(type)
		        $next[0].offsetWidth // force reflow
		        $active.addClass(direction)
		        $next.addClass(direction)
		        $next.addClass('test')
		        this.$element.trigger('slide')
		        this.$element.one($.support.transition.end, function () {
		          $next.removeClass([type, direction].join(' ')).addClass('active')
		          $active.removeClass(['active', direction].join(' '))
		          that.sliding = false
		          setTimeout(function () { that.$element.trigger('slid') }, 0)
		        })
		      } else {
		        this.$element.trigger(e)
		        if (e.isDefaultPrevented()) return
		        $active.removeClass('active')
		        $next.addClass('active')
		        this.sliding = false
		        this.$element.trigger('slid')
		      }

		      isCycling && this.cycle()

		      return this
		    }
		});
	};
}
function scrolling(){
	// if( Modernizr.mq('(min-width: 1024px)') ) {
		$("body").niceScroll({
			cursorcolor: "#ffff",
			cursoropacitymin: 0,
			cursoropacitymax: 0,
			cursorwidth: 0,
			smoothscroll: true,
			scrollspeed: 150,
			horizrailenabled:false,
			autohidemode: 'hidden'
		});
	// }
}