$(document).ready(function(){
	$(window).load(function(){

		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		headerPushTop();
		new WOW().init();
		$('.main-panel').css({
			// 'min-height': ( $pageHeight + 2 ) - $navHeight,
			'margin-bottom' : $quickLinks,
			// 'margin-top': $navHeight,
			'padding-bottom': $footerHeight
		});
		$('menu-container').css({
			'min-height': $pageHeight,
		});

		
		
		$(document).on('scroll', function(){
			var header = $('header.site-header');
			var curScroll;
			var doc = document.documentElement;
		  	var w = window;
		  	curScroll = w.scrollY || doc.scrollTop;
			if(curScroll >= '10' ){
		       header.addClass('white');
		    }
		    else{
		    	header.removeClass('white');
		    }
		});

		var swiper = new Swiper('.swiper-container', {
	      slidesPerView: 3,
	      spaceBetween: 100,
	      centeredSlides: true,
	      initialSlide: 3,
	      slideToClickedSlide: true,
    	  /* onClick: function(swiper,event){
		        swiper.slideNext();
				}, */
	      pagination: {
	        el: '.swiper-pagination',
	        clickable: true,
	      },
				navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
	      breakpoints: {
	      	320: {
	          slidesPerView: 2,
	          spaceBetween: 20,
	        },
	        425: {
	          slidesPerView: 2,
	          spaceBetween: 20,
	        },
	        768: {
	          slidesPerView: 3,
	          spaceBetween: 40,
	        },
	        1024: {
	          slidesPerView: 3,
	          spaceBetween: 100,
	        },
	        1200: {
	          slidesPerView: 4,
	          spaceBetween: 100,
	        },
	      }
	    });

	   $('.slick').slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: false,
		  slidesToShow: 3,
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 3,
		        infinite: true,
		        dots: true
		      }
		    },
		    {
		      breakpoint: 600,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 1
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
		    // You can unslick at a given breakpoint now by adding:
		    // settings: "unslick"
		    // instead of a settings object
		  ]
		});

	    if ($('.home').is(":visible")) {

				$('#carousellABM').carousel({
					wrap: false
				});

				$('#carousellABM').bind('mousewheel', function(e) {
						if(e.originalEvent.wheelDelta /120 > 0) {
								$(this).carousel('prev');
						} else {
								$(this).carousel('next');
						}
				});
				$('#carousellABM').on('slid', '', checkitem);  // on caroussel move
				$('#carousellABM').on('slid.bs.carousel', '', checkitem); // on carousel move

	    }


	$(document).ready(function(){               // on document ready
	    checkitem();
	});

	function checkitem()                        // check function
	{
	    var $this = $('#carousellABM');
	    if($('.carousel-inner .carousel-item:first').hasClass('active')) {
	        $('.carousel-control-prev').hide();
	        $('.carousel-control-next').show();
	    } else if($('.carousel-inner .carousel-item:last').hasClass('active')) {
	        $('.carousel-control-prev').show();
	        $('.carousel-control-next').hide();
	    } else {
	        $('.carousel-control-prev').show();
	        $('.carousel-control-next').show();
	    } 
	}
	function slideIn(el) { 
	  return TweenMax.from(el, 0.7, {autoAlpha:0, x:"+=50" 
		 }) 
	}

	function slideOut(el) { 
	  return TweenMax.to(el, 0.7, {autoAlpha:0, x:"+=50"   
		})  
		
	}
	var currColor ='';
	$('#carousellABM').on('slide.bs.carousel',function(e){
		console.log();

		var indicatorId = $('.carousel-indicators > li.active').data('slide-to');
		$('.carousel-item').removeClass('new-current');
		$(this).find('.carousel-item.active').addClass('current');
		// $(this).find('.carousel-item.carousel-item-next').addClass('new-current');
		$(this).find('.carousel-item').eq(e.to).addClass('new-current');
		$(this).find('.carousel-item.active.current .paph-wrapper').addClass('out');
		$(this).find('.carousel-item.new-current .paph-wrapper').addClass('in');

		currColor = $(this).find('.carousel-item.active').data('color');
		var newColor = $(this).find('.carousel-item.new-current').data('color');
		$('body').removeClass(currColor);
		$('body').addClass(newColor);

	})
	$('#carousellABM').on('slid.bs.carousel',function(){
		$('.carousel-item').removeClass('current');
		$('.carousel-item .paph-wrapper').removeClass('out');
		// $(this).find('.carousel-item.active').addClass('new-current');
		$(this).find('.carousel-item.new-current .paph-wrapper').addClass('in');
	})

	var tl= new TimelineMax();
	// tl.add(slideIn(".in")); 
	tl.addPause()
	tl.add(slideOut(".out"));

	tl.call(tl.play, [0], tl);

	//If you write your own code, remember hex color shortcuts (eg., #fff, #000)

	/*  returned value: (String)
	rgba(251,175,255,1)
	*/

		$('.back-to-top').hide();
		
		$('.back-to-top').css({
			'bottom' : $quickLinks + 35
		});

		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('.back-to-top').fadeIn();
			} else {
				$('.back-to-top').fadeOut();
			}
		});
		$('.back-to-top a, .mobile-back-to-top a').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});

		$('.loader-overlay').fadeOut(200);

		$('.mailto-container .mailto-contain, .career-container .career-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});
		$(window).scroll(function () {
			if( $('header.site-header').hasClass('push-top') ) {
				$('.mailto-container .mailto-contain, .career-container .career-contain').css({
					'padding-bottom' : $quickLinks,
					'padding-top' : 0
				});
			} else {
				$('.mailto-container .mailto-contain, .career-container .career-contain').css({
					'padding-bottom' : $quickLinks,
					'padding-top' : $navHeight
				});
			}
		});

		// Sub Menus
		$('.menu-container .menu-contain nav ul li.menu-item-has-children').each(function(){
			$(this).append('<button type="button" class="submenu-button"><i class="fas fa-angle-right"></i></button>');
		});
		$('.menu-container .menu-contain nav ul li .sub-menu').each(function(){
			$(this).prepend('<li><button type="button" class="submenu-close-button"><i class="fas fa-angle-left"></i>Back</button></li>');
		});

	});
});

function headerPushTop(){
	var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;
  var prevDirection = 0;

  var header = $('header.site-header');

  var checkScroll = function() {

    /*
    ** Find the direction of scroll
    ** 0 - initial, 1 - up, 2 - down
    */

    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) { 
      //scrolled up
      direction = 2;
    }
    else if (curScroll < prevScroll) { 
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }
    
    prevScroll = curScroll;
  };

  var toggleHeader = function(direction, curScroll) {
    if (direction === 2 && curScroll > 52) {
      header.addClass('push-top');
      prevDirection = direction;
    }
    else if (direction === 1) {
      header.removeClass('push-top');
      prevDirection = direction;
      	if (curScroll < '10' ){
      		alert('working');
		    // header.removeClass('white');
		}
    }
  };


	// navigate to services container if page is services, and active in query param is present
	var servicesPage = $('.services-page-template');

	if (servicesPage && servicesPage.length) {
		var params = new URLSearchParams(window.location.search);

		if (params && params.get('active') >= 0) {
			var active = params.get('active');
			var lists = $('#myTab').find('.nav-item');
			// click desktop nav
			lists.eq(active).find('a').click()

			// click mobile nav
			$('#tab_selector option[value="'+ active +'"]').prop('selected', true);
		}
	}

	// blogs pagination handler
	var servicesPage = $('.work-archive-template');
	if (servicesPage && servicesPage.length) {
		var params = new URLSearchParams(window.location.search);

		if (params && params.get('active')) {
			var active = params.get('active');
			console.log('active', active);
			var lists = $('#myTab').find('.nav-item');
			lists.eq(active).find('a').click()
			$('#tab_selector option[value="'+ active +'"]').prop('selected', true);
		}

		setTimeout(() => {
			$('.blog--loading').hide();
			$('.blog--loaded').css({'opacity': '1'});
		}, 1000);

		$('.nav-link').on('click', function (e) {
			e.preventDefault();
			e.stopImmediatePropagation()
			window.location.href = $(this).data('url');
		});

		$('#tab_selector').on('change', function (e) {
			// $('.nav-tabs li a').eq($(this).val()).tab('show');
			e.preventDefault();
			e.stopImmediatePropagation()
			window.location.href = $(this).find(':selected').data('url');
	});
	}
  
  window.addEventListener('scroll', checkScroll);
}