$(document).ready(function(){
	$(window).load(function(){

		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		$(document).on('click', '.upload-button', function(e){
			e.preventDefault();
		    $('#input_3_1').trigger('click');
		});

		$('.main-content').addClass('animate__fadeInLeft');
		$('#tab_selector').on('change', function (e) {
		    $('.nav-tabs li a').eq($(this).val()).tab('show');
		});

		$(".item-list2 .items").each(function() {
		    if (isScrolledIntoView($(this))) {
		      $(this).find(".graph-line").addClass("graph-75");
		      $(this).find(".graph-line-2").addClass("opacity");
		    }
		  });
		$('.address').on('click', function(){
			$('.map').removeClass('animate__fadeOutDown');
		    $('.map').addClass('animate__semifadeIn');
		    $('.map').removeClass('d-none');

		    $('.mobile-container').addClass('d-none');

		    $('.main-content').addClass('animate__semifadeOut');
		    $('.main-content').removeClass('animate__semifadeIn');

			$('.email').addClass('animate__semifadeOut');
			$('.email').removeClass('animate__semifadeIn');

			$('.number').addClass('animate__semifadeOut');
			$('.number').removeClass('animate__semifadeIn');

		    $('#contact-form').addClass('animate__semifadeOut');
		    $('#contact-form').removeClass('animate__semifadeIn');
		});
		$('.map .close').on('click', function(){
		    $('.map').removeClass('animate__semifadeIn');
		    $('.map').addClass('animate__fadeOutDown');
		    $('.map').addClass('d-none');

		    $('.mobile-container').removeClass('d-none');

		    $('.main-content').removeClass('animate__semifadeOut');
		    $('.main-content').addClass('animate__semifadeIn');

			$('.email').removeClass('animate__semifadeOut');
			$('.email').addClass('animate__semifadeIn');

			$('.number').removeClass('animate__semifadeOut');
			$('.number').addClass('animate__semifadeIn');
			
		    $('#contact-form').removeClass('animate__semifadeOut');
		    $('#contact-form').addClass('animate__semifadeIn');
		});
		$('.shortcode-form .gform_wrapper').addClass('no-value');
		
		$(document).on('click', '.gform_button', function(e){
			setTimeout(function(){		    
				// if ($('validation_error').length ) {
					
					console.log($(this).parent().siblings('.validation_error').text());
				// }
			}, 5000);
			
		})
		$(document).on('change', '#input_3_1', function(e){
			var file = $(this).val();
			if (file != '') {
				$(this).parents(".gform_wrapper").removeClass('no-value').addClass('has-value');
			}
			else{
				console.log('no-value');
			}
		})


		$(document).on('click','.nav-link', function(){
			setTimeout(function(){
		    	$("body").getNiceScroll().resize();
		    }, 700);
		});


		$(document).on('click', 'input.search-input', function(e){
			e.preventDefault();
		    $('#blog-title').removeClass('animate__fadeIn');
		    $('#blog-title').addClass('animate__fadeOut');
		    $('#archive-list').addClass('animate__fadeOut');
		    $('#archive-search').removeClass('animate__fadeOut');
		    setTimeout(function(){
		    	$('#blog-title').addClass('d-none');
		    	$('#archive-list').addClass('d-none');
		    }, 200);
		    setTimeout(function(){
		       $('#search-form').removeClass('col-6 col-md-4 col-4').addClass('col-12');
		       $('#archive-search').removeClass('d-none');
		       $('#archive-search').addClass('animate__fadeIn');
		    }, 300);
		    setTimeout(function(){
		       // $('#search-form').removeClass('col-4').addClass('col-12');
		       $('#archive-search').removeClass('d-none');
		    }, 500);
		});
		$(document).on('focusout', 'input.search-input', function(){
		    setTimeout(function(){
		      $('#blog-title').removeClass('d-none');
		      $('#archive-list').removeClass('d-none');
		      $('#blog-title').removeClass('animate__fadeOut');
		      $('#blog-title').addClass('animate__fadeIn');
		      $('#archive-list').removeClass('animate__fadeOut');
		      $('#archive-list').addClass('animate__fadeIn');
		      $('#archive-search').removeClass('animate__fadeIn');
		      $('#archive-search').addClass('animate__fadeOut');
		      $('#archive-search').addClass('d-none');
		    }, 300);
		    $('#search-form').removeClass('col-12').addClass('col-6 col-md-4 col-4');
		});


		 $(document).on('keyup', '.search-input', function () {
	      	var dInput = $(this).val();
		 	setTimeout(function(){
		      	console.log(dInput);
		      	if (dInput) {
		        	product_live_data_fetch(dInput);
		      	}
	      	}, 500);
	    });
	   

	    function product_live_data_fetch(data) {
	      var s = data;
	      // console.log(s);
	      jQuery.ajax({
	        url: headJS.ajaxurl,
	        type: 'post',
	        data: {
	          action: 'product_live_data_fetch',
	          s: s
	        },
	        beforeSend: function beforeSend(data) {
	          // $("#live_datafetch .loader-container").show();
	          $('#live_datafetch #live-data-container #nav-search-all').html();
	        },
	        success: function success(data) {
	          // $("#live_datafetch .loader-container").hide();
	          $('#archive-search #nav-tabContent #nav-search-all').html(data);
	        }
	      });
	    }

		$('.nav-tabs-dropdown').on("click", "a:not('.active') ", function(event) {  $(this).closest('.nav-tabs').removeClass("open");
    	})
    	$('.nav-tabs-dropdown').on("click", "a.active ", function(event) {        $(this).closest('.nav-tabs').toggleClass("open");
    	});
    	if( Modernizr.mq('(min-width: 768px)') ) {
			$(document).on('mouseover tap', '.methodology-template .second-section .image', function(){
			  $(this).siblings('.tab-content').removeClass('animate__fadeOut');
			  $(this).siblings('.tab-content').addClass('animate__fadeIn');
		      $(this).siblings('.tab-content').removeClass('d-md-none');
		    });
		    $(document).on('mouseleave tap', '.methodology-template .second-section .image', function(){
		      $(this).siblings('.tab-content').addClass('animate__fadeOut');
		      setTimeout(function(){
		      	$(this).siblings('.tab-content').addClass('d-md-none');
		      }, 500);
		    });
		}

		var $startColor = $('.carousel-item.active').data('color');
		$('body').addClass($startColor);

		$('.menu-container .menu-contain').css({
			// 'padding-bottom' : $quickLinks,
			// 'padding-top' : $navHeight
		});

		$('.mobile-menu').click(function () {
			$('body').toggleClass('push-toleft');
			$('.push-left').toggleClass('pushleft-open');
		});

		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			// setTimeout(function(){
				$('.menu-container').addClass('animate');
			// }, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			// setTimeout(function(){
				$('.menu-container').removeClass('show');
			// }, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

		$(document).on('click', '.testimonial-item-contain', function(){
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_testimonial',
					id : $(this).data('id')
				},
				beforeSend: function(){
					$('body, html').css({
						'overflow' : 'hidden'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function(response) {
					
					setTimeout(function(){
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
			
				}
			});
		});

		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.apply-btn', function(e){
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function(){
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function(e){
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

		$(document).on('click', '.parent-backdrop .backdrop', function(){
			$('body, html').removeAttr('style');
			$(this).parent('.parent-backdrop').removeClass('show');
			setTimeout(function(){
				$(this).parent('.parent-backdrop').removeClass('animate');
			}, 300);
		});

	});
});